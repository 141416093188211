@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';
@import '~@fontsource/roboto-mono';

body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-color: black;
  overscroll-behavior-x: none;
}

.MuiBox-root,
.MuiStack-root,
.MuiDivider-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
}

.rootClass {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
