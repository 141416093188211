body {
  margin: 0;
  background: #eee;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    '.SFNSText-Regular',
    sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-input {
  min-height: 160px;
  resize: none;
  font-size: 16px;
  position: relative;
  tab-size: 1;
  padding: 15px 10px;
  caret-color: #444;
  max-height: 100%;
  overflow: auto;
  outline: none;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: #d3d3d3;
  color: #0c0c0c;
  padding: 2px 0.25rem;
  font-family: monospace;
  font-size: 94%;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.editor-code {
  background-color: #f5f5f5;
  color: #0c0c0c;
  font-family: monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1.2;
  margin: 1rem 0;
  padding-bottom: 0.5rem;
}

.editor-heading-h2 {
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.3;
  margin: 0.75rem 0;
  padding-bottom: 0.4rem;
}

.editor-heading-h3 {
  font-size: 1.75rem;
  font-weight: normal;
  line-height: 1.4;
  margin: 0.5rem 0;
  padding-bottom: 0.3rem;
}

.editor-heading-h4 {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.4;
  margin: 0.5rem 0;
}

.editor-heading-h5 {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.5;
  margin: 0.5rem 0;
}

.editor-heading-h6 {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  margin: 0.5rem 0;
}

.editor-quote {
  margin: 0;
  margin-left: 16px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 8px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 8px;
}

.editor-listitem {
  margin: 8px 16px 8px 16px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}
