.inspectorContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 8px);
  padding: 8px;
  max-height: 100vh;
  overflow: hidden;
  user-select: none;
  z-index: 200;
}

.dragger {
  width: 8px;
  cursor: ew-resize;
  padding: 4px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 300;
}

.draggerLeft {
  width: 8px;
  cursor: ew-resize;
  padding: 4px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
}

.editor {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 600px;
  /* height: 100vh; */
}

.pixicontainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background-color: #ffffff;
}

.hide {
  display: none;
}

.nodeTitle {
  text-align: center;
  padding: 16px;
}

.updateTypeSelection {
  margin: 8px 0 8px 0;
  padding: 8px 8px 0px 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.updateTypeSelectionRow {
  margin: 8px 0 0 0;
}

.intervalFrequency {
  width: 80px;
}

.inputContainer {
  margin: 8px 0 8px 0;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.inputContainer > *:not(:last-child) {
  margin-bottom: 16px;
}

.outputContainer {
  margin: 8px 0 8px 0;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
}

.slider {
  width: unset !important;
  margin: 0 16px;
}

.opacity30 {
  opacity: 0.3;
}

.scrollablePortal {
  max-height: 50vh;
  overflow-y: auto;
}

.serializedNode {
  font-size: 12px;
  white-space: pre-wrap;
}

.colorPickerSwatch {
  width: 100%;
  height: 2em;
  line-height: 2em;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
}

.propertyTag {
  cursor: pointer;
  width: 48px;
  text-align: center;
  border-radius: 3px !important;
  margin-right: 8px !important;
}

.brightOnDark {
  background: rgba(138, 155, 168, 0.2);
  color: #bfccd6;
}

.darkOnBright {
  background: #bfccd6;
  color: #182026;
}

.editablePropertyName {
  line-height: 2em;
  padding: 0 16px;
  min-width: 220px;
}

.editablePropertyName2 {
  line-height: 2em;
  padding: 0 16px;
  /* min-width: 220px; */
  background: rgba(41, 55, 66, 1);
  color: #bfccd6;
}

.minMaxInput input {
  width: 64px !important;
}

.textArea {
  max-height: 50vh;
}

.hybridContainer {
  pointer-events: none;
  position: absolute;
  z-index: 0;
  transform-origin: top left;
  overflow: auto;
}

.hybridContainer:focus {
  outline: none;
}

.hybridContainerFocused {
  pointer-events: auto;
}

.hybridContainerEditButton {
  position: absolute !important;
  top: 16px;
  right: 16px;
  width: 24px;
  min-width: 24px !important;
  pointer-events: auto;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.35));
  z-index: 10;
}

.hybridContainerEditButton:hover {
  background-color: rgba(225, 84, 125, 1) !important;
}

.componentSelected {
  position: relative;
}

.componentSelected::after {
  content: ' ';
  --tw-border-opacity: 1;
  border-color: rgba(195, 171, 84, var(--tw-border-opacity));
  border-style: dashed;
  border-width: 1px;
  display: block;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.componentHover {
  position: relative;
}

.componentHover::after {
  content: ' ';
  --tw-border-opacity: 1;
  border-color: rgba(38, 128, 235, var(--tw-border-opacity));
  border-style: dashed;
  border-width: 1px;
  display: block;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.resizeElement {
  display: flex;
  padding: 10px;
}

.resizeElementLabel {
  display: flex;
  padding: 8px 16px;
}

.resizeHandle {
  background-color: none;
}

.resizeHandle:hover {
  background-color: rgba(225, 84, 125, 0.1);
}

.plugAndPlaygroundIcon {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 40px;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
  user-select: none;
  border-radius: 32px !important;
  z-index: 10;
}

.pnpHeader {
  position: absolute;
  left: 168px;
  bottom: 28px;
  filter: drop-shadow(0px 0px 24px rgba(94, 69, 69, 0.15));
  background: none;
  z-index: 10;
}

.brightPlaceholder input::placeholder {
  color: #f5f5f5;
}

.nodeSearch {
  position: absolute;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
  z-index: 10;
}

.noSelect:focus,
.noSelect > input:focus,
.noSelect > span:focus {
  outline: none !important;
}

.floatingNodeMenu {
  position: absolute;
  transform: translate(-50%, -50%) !important;
  z-index: 100;
}

.imageIcon {
  display: flex;
  height: inherit;
  width: inherit;
}

.imageMenuIcon {
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.iconRoot {
  text-align: 'center';
}

.propertyContainerContent {
  overflow: auto;
}

.menuItemButton {
  border-radius: 0 !important;
}
